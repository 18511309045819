//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "GroupPublicJoin",
    props: ['id'],
    methods: {
      join() {
        this.$http({
          url: 'groups/' + this.id + '/join',
          method: 'POST'
        }).then((response) => {
          if (response.data['status'] == 'group_joined') {
            this.$q.notify({
              message: 'You have successfully joined this group.',
              color: 'positive'
            });
            window.location.reload();
            return;
          }
          if (response.data['status'] == 'already_member') {
            this.$q.notify({
              message: 'You are already a member of this group.',
              color: 'primary'
            });
            return;
          }
          if (response.data['status'] == 'private_entity') {
            this.$q.dialog({
              message: 'This group has switched to private and only the group captain can invite members into the group.',
              title: 'This group is private'
            });
            return;
          }
        })
      }
    }
}
